import Axios, { AxiosError, type AxiosRequestConfig } from 'axios';
import { createAuthStore } from '#/common/stores/auth/auth.store';
import { env } from '#/utils/env';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: env.NEXT_PUBLIC_ENTREMALHAS_API_URL,
});

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source();

  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    timeout: 30 * 1000, // 30 seconds
    cancelToken: source.token,
  })
    .then(({ data }) => data)
    .catch((error) => {
      if (Axios.isCancel(error)) {
        throw new Error('Request cancelled');
      }

      if (error.response) {
        throw new AxiosError(
          error.response.data.message,
          error.response.data.statusCode,
          error.config,
          error.request,
          error.response,
        );
      }

      throw error;
    });

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    if (typeof window !== 'undefined') {
      const { token } = createAuthStore().getState();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default customInstance;

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;
