import Link from 'next/link';
import { useAuthStore } from '#/common/stores/auth/AuthStoreProvider';
import { HeaderNavCart } from '../Cart';
import { Icon } from '../Icon';
import { IconWithLabel } from '../IconWithLabel';
import { Button } from '../ui/button';

export function HeaderNavIcons() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  return (
    <div className="flex items-center gap-6">
      <Button
        asChild
        className="hidden w-max items-center gap-2 text-sm lg:flex 2xl:text-base"
        variant="ghost"
        size="lg"
      >
        <Link
          href={isAuthenticated ? '/minha-conta' : '/login'}
          prefetch={false}
        >
          <Icon
            name="circle-user"
            size={30}
            strokeWidth={1.4}
          />
          {isAuthenticated ? 'Minha Conta' : 'Entre ou Cadastre-se'}
        </Link>
      </Button>
      <HeaderNavCart />
      <Button
        asChild
        variant="ghost"
        size="lg"
        title="Acessar página de favoritos"
      >
        <Link
          href={isAuthenticated ? '/minha-conta/favoritos' : '/login'}
          className="w-9"
          prefetch={false}
        >
          <IconWithLabel
            icon="heart"
            size={30}
            label="FAVORITOS"
          />
        </Link>
      </Button>
      <Button
        asChild
        variant="ghost"
        size="icon"
        title="Acessar página Minha Conta"
      >
        <Link
          href={isAuthenticated ? '/minha-conta' : '/login'}
          className="lg:hidden"
          prefetch={false}
        >
          <IconWithLabel
            icon="circle-user"
            size={30}
            label="CONTA"
          />
        </Link>
      </Button>
    </div>
  );
}
