import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import { Layout } from '#/common/components/Layout';
import { ScrollToTop } from '#/common/components/ScrollToTop';
import '#/styles/globals.css';
import Head from 'next/head';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import { useEffect, useState } from 'react';
import { CombineProviders } from '#/common/components/CombineProviders';
import { NavigationMenuProvider } from '#/common/components/NavigationMenuProvider';
import { Toaster } from '#/common/components/ui/toaster';
import { useAxeCoreReporter } from '#/common/hooks/useAxeCoreReporter';
import { AuthStoreProvider } from '#/common/stores/auth/AuthStoreProvider';
import { CartStoreProvider } from '#/common/stores/cart/CartStoreProvider';

const formatUrl = (path: string) => {
  const baseUrl = 'https://entremalhas.com.br';
  const formattedPath = path === '/' ? '' : path;
  return `${baseUrl}${formattedPath}`.split('?')[0];
};

export default function App({ Component, pageProps, router }: AppProps) {
  const canonicalUrl = formatUrl(router.asPath);
  const [shouldRender, setShouldRender] = useState(false);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      }),
  );

  useAxeCoreReporter();

  useEffect(() => {
    if (!shouldRender) {
      setShouldRender(true);
    }
  }, [shouldRender]);

  return (
    shouldRender && (
      <CombineProviders
        providers={[
          { provider: AuthStoreProvider },
          { provider: QueryClientProvider, props: { client: queryClient } },
          { provider: NavigationMenuProvider },
          {
            provider: ThemeProvider,
            props: {
              attribute: 'class',
              defaultTheme: 'light',
              enableSystem: true,
              disableTransitionOnChange: true,
            },
          },
          {
            provider: HydrationBoundary,
            props: { state: pageProps.dehydratedState },
          },
          { provider: CartStoreProvider },
          {
            provider: NuqsAdapter,
          },
        ]}
      >
        <Head>
          <title>Entremalhas</title>
          <link
            rel="canonical"
            href={canonicalUrl}
          />
          <link
            rel="icon"
            href="/favicon.ico"
          />
          <meta
            name="description"
            content="Loja virtual da Entremalhas, especializada em tecidos e malhas."
          />
        </Head>
        <Layout>
          <Component {...pageProps} />
        </Layout>
        <Toaster />
        <ScrollToTop />
        <ReactQueryDevtools />
      </CombineProviders>
    )
  );
}
