import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

interface State {
  token: string | null;
  isAuthenticated: boolean;
}

type Actions = {
  updateToken: (token: string | null) => void;
};

const defaultInitState: State = {
  token: null,
  isAuthenticated: false,
};

export const initAuthStore = (): State => {
  return defaultInitState;
};

export type AuthStore = State & Actions;

export const createAuthStore = (initState: State = defaultInitState) =>
  createStore(
    persist<AuthStore>(
      (set) => ({
        ...initState,
        updateToken: (token) => set({ token, isAuthenticated: !!token }),
      }),
      { name: 'auth-entremalhas:2.0.0' },
    ),
  );
