import { produce } from 'immer';
import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';
import type { GetCustomerCart200ProductsItem } from '#/http/api/generated.schemas';

export type ProductOnCart = [product_id: number, value: GetCustomerCart200ProductsItem];

type State = {
  productsOnCart: ProductOnCart[];
  currentStep: number;
  isCartOpen: boolean;
  differentAddresses: boolean;
  coupon?: string;
  shippingPostcode?: string;
  isLocalPickup: boolean;
};

interface UpdateProductQuantityOnCart {
  product_id: number;
  quantity: number;
}

type Actions = {
  reset: () => void;
  addProductToCart: (props: GetCustomerCart200ProductsItem) => void;
  updateProductQuantityOnCart: ({
    product_id,
    quantity,
  }: UpdateProductQuantityOnCart) => void;
  setProductsOnCart: (products: ProductOnCart[]) => void;
  removeProductFromCart: (product_id: number) => void;
  existsProductOnCart: (product_id: number) => boolean;
  clearProductsOnCart: () => void;
  setCurrentStep: (step: number) => void;
  toggleIsCartOpen: () => void;
  toggleDifferentAddresses: () => void;
  setCoupon: (coupon: string) => void;
  setShippingPostcode: (postcode: string) => void;
  nextStep: () => void;
  toggleIsLocalPickup: () => void;
};

const defaultInitState: State = {
  productsOnCart: [],
  currentStep: 1,
  isCartOpen: false,
  differentAddresses: false,
  coupon: '',
  shippingPostcode: '',
  isLocalPickup: false,
};

export const initCartStore = (): State => {
  return defaultInitState;
};

export type CartStore = State & Actions;

export const createCartStore = (initState: State = defaultInitState) =>
  createStore(
    persist<CartStore>(
      (set, get) => ({
        ...initState,
        reset: () => set(defaultInitState),
        toggleIsLocalPickup: () => {
          set((state) => ({ isLocalPickup: !state.isLocalPickup }));
        },
        setProductsOnCart: (products) => {
          set({ productsOnCart: products });
        },
        setCurrentStep: (step) => {
          set({ currentStep: step });
        },
        nextStep: () => {
          const { currentStep } = get();
          if (currentStep < 5) {
            set({ currentStep: currentStep + 1 });
          }
        },
        toggleIsCartOpen: () => {
          set((state) => ({ isCartOpen: !state.isCartOpen }));
        },
        toggleDifferentAddresses: () => {
          set((state) => ({ differentAddresses: !state.differentAddresses }));
        },
        setShippingPostcode(postcode) {
          set({ shippingPostcode: postcode });
        },
        setCoupon: (coupon) => {
          set({ coupon });
        },
        addProductToCart: (product) => {
          set((prevState) => {
            const mappedProducts = new Map(prevState.productsOnCart);
            mappedProducts.set(product.product_id, product);
            const productList: Array<ProductOnCart> = Array.from(mappedProducts);
            return produce(prevState, (draft) => {
              draft.productsOnCart = productList;
            });
          });
        },
        updateProductQuantityOnCart: ({ product_id, quantity }) => {
          set((prevState) => {
            const mappedProducts = new Map(prevState.productsOnCart);
            const props = mappedProducts.get(product_id);

            if (props) {
              mappedProducts.set(product_id, { ...props, quantity });
            }

            const productsOnCart = Array.from(mappedProducts);

            return produce(prevState, (draft) => {
              draft.productsOnCart = Array.from(productsOnCart);
            });
          });
        },
        removeProductFromCart: (product_id) => {
          set((prevState) =>
            produce(prevState, (draft) => {
              const productsOnCart = new Map(prevState.productsOnCart);
              productsOnCart.delete(product_id);
              draft.productsOnCart = Array.from(productsOnCart);
            }),
          );
        },
        existsProductOnCart: (product_id) =>
          get().productsOnCart.some(([id]) => product_id === id),
        clearProductsOnCart() {
          set((prevState) =>
            produce(prevState, (draft) => {
              draft.productsOnCart = [];
            }),
          );
        },
      }),
      { name: 'cart-entremalhas:2.0.0' },
    ),
  );
