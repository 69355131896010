/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Entremalhas BFF
 * BFF for Entremalhas
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import type {
  GetSiteCatalogFilters200Item,
  GetSiteFooterNavigation200Item,
  GetSiteHeaderNavigation200Item,
  GetSiteHome200,
  GetSiteSuggestProductsParams,
  PostLeadBody,
} from '../generated.schemas';

import getSiteCatalogFiltersMutator from '../../client';
import type { ErrorType as GetSiteCatalogFiltersErrorType } from '../../client';
import getSiteHomeMutator from '../../client';
import type { ErrorType as GetSiteHomeErrorType } from '../../client';
import getSiteHeaderNavigationMutator from '../../client';
import type { ErrorType as GetSiteHeaderNavigationErrorType } from '../../client';
import getSiteFooterNavigationMutator from '../../client';
import type { ErrorType as GetSiteFooterNavigationErrorType } from '../../client';
import getSiteSuggestProductsMutator from '../../client';
import type { ErrorType as GetSiteSuggestProductsErrorType } from '../../client';
import postLeadMutator from '../../client';
import type {
  BodyType as PostLeadBodyType,
  ErrorType as PostLeadErrorType,
} from '../../client';

type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];

/**
 * Retorna os filtros disponíveis para o catálogo
 * @summary Busca filtros
 */
export const getSiteCatalogFilters = (
  options?: SecondParameter<typeof getSiteCatalogFiltersMutator>,
  signal?: AbortSignal,
) => {
  return getSiteCatalogFiltersMutator<GetSiteCatalogFilters200Item[]>(
    { url: '/site/catalog-filters', method: 'GET', signal },
    options,
  );
};

export const getGetSiteCatalogFiltersQueryKey = () => {
  return ['/site/catalog-filters'] as const;
};

export const getGetSiteCatalogFiltersQueryOptions = <
  TData = Awaited<ReturnType<typeof getSiteCatalogFilters>>,
  TError = GetSiteCatalogFiltersErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteCatalogFilters>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteCatalogFiltersMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSiteCatalogFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteCatalogFilters>>> = ({
    signal,
  }) => getSiteCatalogFilters(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSiteCatalogFilters>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSiteCatalogFiltersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSiteCatalogFilters>>
>;
export type GetSiteCatalogFiltersQueryError = GetSiteCatalogFiltersErrorType<unknown>;

export function useGetSiteCatalogFilters<
  TData = Awaited<ReturnType<typeof getSiteCatalogFilters>>,
  TError = GetSiteCatalogFiltersErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteCatalogFilters>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteCatalogFilters>>,
        TError,
        Awaited<ReturnType<typeof getSiteCatalogFilters>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteCatalogFiltersMutator>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteCatalogFilters<
  TData = Awaited<ReturnType<typeof getSiteCatalogFilters>>,
  TError = GetSiteCatalogFiltersErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteCatalogFilters>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteCatalogFilters>>,
        TError,
        Awaited<ReturnType<typeof getSiteCatalogFilters>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteCatalogFiltersMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteCatalogFilters<
  TData = Awaited<ReturnType<typeof getSiteCatalogFilters>>,
  TError = GetSiteCatalogFiltersErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteCatalogFilters>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteCatalogFiltersMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca filtros
 */

export function useGetSiteCatalogFilters<
  TData = Awaited<ReturnType<typeof getSiteCatalogFilters>>,
  TError = GetSiteCatalogFiltersErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteCatalogFilters>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteCatalogFiltersMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSiteCatalogFiltersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retorna informações para a home do site
 * @summary Busca informações para a home
 */
export const getSiteHome = (
  options?: SecondParameter<typeof getSiteHomeMutator>,
  signal?: AbortSignal,
) => {
  return getSiteHomeMutator<GetSiteHome200>(
    { url: '/site/home', method: 'GET', signal },
    options,
  );
};

export const getGetSiteHomeQueryKey = () => {
  return ['/site/home'] as const;
};

export const getGetSiteHomeQueryOptions = <
  TData = Awaited<ReturnType<typeof getSiteHome>>,
  TError = GetSiteHomeErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHome>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteHomeMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSiteHomeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteHome>>> = ({ signal }) =>
    getSiteHome(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSiteHome>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSiteHomeQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteHome>>>;
export type GetSiteHomeQueryError = GetSiteHomeErrorType<unknown>;

export function useGetSiteHome<
  TData = Awaited<ReturnType<typeof getSiteHome>>,
  TError = GetSiteHomeErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHome>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteHome>>,
        TError,
        Awaited<ReturnType<typeof getSiteHome>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteHomeMutator>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteHome<
  TData = Awaited<ReturnType<typeof getSiteHome>>,
  TError = GetSiteHomeErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHome>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteHome>>,
        TError,
        Awaited<ReturnType<typeof getSiteHome>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteHomeMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteHome<
  TData = Awaited<ReturnType<typeof getSiteHome>>,
  TError = GetSiteHomeErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHome>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteHomeMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca informações para a home
 */

export function useGetSiteHome<
  TData = Awaited<ReturnType<typeof getSiteHome>>,
  TError = GetSiteHomeErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHome>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteHomeMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSiteHomeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retorna informações para a navegação do header
 * @summary Busca informações para a navegação do header
 */
export const getSiteHeaderNavigation = (
  options?: SecondParameter<typeof getSiteHeaderNavigationMutator>,
  signal?: AbortSignal,
) => {
  return getSiteHeaderNavigationMutator<GetSiteHeaderNavigation200Item[]>(
    { url: '/site/header-navigation', method: 'GET', signal },
    options,
  );
};

export const getGetSiteHeaderNavigationQueryKey = () => {
  return ['/site/header-navigation'] as const;
};

export const getGetSiteHeaderNavigationQueryOptions = <
  TData = Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
  TError = GetSiteHeaderNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHeaderNavigation>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteHeaderNavigationMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSiteHeaderNavigationQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteHeaderNavigation>>> = ({
    signal,
  }) => getSiteHeaderNavigation(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSiteHeaderNavigationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSiteHeaderNavigation>>
>;
export type GetSiteHeaderNavigationQueryError = GetSiteHeaderNavigationErrorType<unknown>;

export function useGetSiteHeaderNavigation<
  TData = Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
  TError = GetSiteHeaderNavigationErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHeaderNavigation>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
        TError,
        Awaited<ReturnType<typeof getSiteHeaderNavigation>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteHeaderNavigationMutator>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteHeaderNavigation<
  TData = Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
  TError = GetSiteHeaderNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHeaderNavigation>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
        TError,
        Awaited<ReturnType<typeof getSiteHeaderNavigation>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteHeaderNavigationMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteHeaderNavigation<
  TData = Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
  TError = GetSiteHeaderNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHeaderNavigation>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteHeaderNavigationMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca informações para a navegação do header
 */

export function useGetSiteHeaderNavigation<
  TData = Awaited<ReturnType<typeof getSiteHeaderNavigation>>,
  TError = GetSiteHeaderNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteHeaderNavigation>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteHeaderNavigationMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSiteHeaderNavigationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retorna informações para a navegação do footer
 * @summary Busca informações para a navegação do footer
 */
export const getSiteFooterNavigation = (
  options?: SecondParameter<typeof getSiteFooterNavigationMutator>,
  signal?: AbortSignal,
) => {
  return getSiteFooterNavigationMutator<GetSiteFooterNavigation200Item[]>(
    { url: '/site/footer-navigation', method: 'GET', signal },
    options,
  );
};

export const getGetSiteFooterNavigationQueryKey = () => {
  return ['/site/footer-navigation'] as const;
};

export const getGetSiteFooterNavigationQueryOptions = <
  TData = Awaited<ReturnType<typeof getSiteFooterNavigation>>,
  TError = GetSiteFooterNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteFooterNavigation>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteFooterNavigationMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSiteFooterNavigationQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteFooterNavigation>>> = ({
    signal,
  }) => getSiteFooterNavigation(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSiteFooterNavigation>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSiteFooterNavigationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSiteFooterNavigation>>
>;
export type GetSiteFooterNavigationQueryError = GetSiteFooterNavigationErrorType<unknown>;

export function useGetSiteFooterNavigation<
  TData = Awaited<ReturnType<typeof getSiteFooterNavigation>>,
  TError = GetSiteFooterNavigationErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteFooterNavigation>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteFooterNavigation>>,
        TError,
        Awaited<ReturnType<typeof getSiteFooterNavigation>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteFooterNavigationMutator>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteFooterNavigation<
  TData = Awaited<ReturnType<typeof getSiteFooterNavigation>>,
  TError = GetSiteFooterNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteFooterNavigation>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSiteFooterNavigation>>,
        TError,
        Awaited<ReturnType<typeof getSiteFooterNavigation>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof getSiteFooterNavigationMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteFooterNavigation<
  TData = Awaited<ReturnType<typeof getSiteFooterNavigation>>,
  TError = GetSiteFooterNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteFooterNavigation>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteFooterNavigationMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Busca informações para a navegação do footer
 */

export function useGetSiteFooterNavigation<
  TData = Awaited<ReturnType<typeof getSiteFooterNavigation>>,
  TError = GetSiteFooterNavigationErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSiteFooterNavigation>>, TError, TData>
  >;
  request?: SecondParameter<typeof getSiteFooterNavigationMutator>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSiteFooterNavigationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Buscar título de produtos que correspondam a uma string de busca
 * @summary Buscar sugestões de produtos
 */
export const getSiteSuggestProducts = (
  params: GetSiteSuggestProductsParams,
  options?: SecondParameter<typeof getSiteSuggestProductsMutator>,
  signal?: AbortSignal,
) => {
  return getSiteSuggestProductsMutator<string[]>(
    { url: '/site/suggest-products', method: 'GET', params, signal },
    options,
  );
};

export const getGetSiteSuggestProductsQueryKey = (
  params: GetSiteSuggestProductsParams,
) => {
  return ['/site/suggest-products', ...(params ? [params] : [])] as const;
};

export const getGetSiteSuggestProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSiteSuggestProducts>>,
  TError = GetSiteSuggestProductsErrorType<unknown>,
>(
  params: GetSiteSuggestProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSiteSuggestProducts>>, TError, TData>
    >;
    request?: SecondParameter<typeof getSiteSuggestProductsMutator>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSiteSuggestProductsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteSuggestProducts>>> = ({
    signal,
  }) => getSiteSuggestProducts(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSiteSuggestProducts>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSiteSuggestProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSiteSuggestProducts>>
>;
export type GetSiteSuggestProductsQueryError = GetSiteSuggestProductsErrorType<unknown>;

export function useGetSiteSuggestProducts<
  TData = Awaited<ReturnType<typeof getSiteSuggestProducts>>,
  TError = GetSiteSuggestProductsErrorType<unknown>,
>(
  params: GetSiteSuggestProductsParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSiteSuggestProducts>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSiteSuggestProducts>>,
          TError,
          Awaited<ReturnType<typeof getSiteSuggestProducts>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof getSiteSuggestProductsMutator>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteSuggestProducts<
  TData = Awaited<ReturnType<typeof getSiteSuggestProducts>>,
  TError = GetSiteSuggestProductsErrorType<unknown>,
>(
  params: GetSiteSuggestProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSiteSuggestProducts>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSiteSuggestProducts>>,
          TError,
          Awaited<ReturnType<typeof getSiteSuggestProducts>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof getSiteSuggestProductsMutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSiteSuggestProducts<
  TData = Awaited<ReturnType<typeof getSiteSuggestProducts>>,
  TError = GetSiteSuggestProductsErrorType<unknown>,
>(
  params: GetSiteSuggestProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSiteSuggestProducts>>, TError, TData>
    >;
    request?: SecondParameter<typeof getSiteSuggestProductsMutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Buscar sugestões de produtos
 */

export function useGetSiteSuggestProducts<
  TData = Awaited<ReturnType<typeof getSiteSuggestProducts>>,
  TError = GetSiteSuggestProductsErrorType<unknown>,
>(
  params: GetSiteSuggestProductsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSiteSuggestProducts>>, TError, TData>
    >;
    request?: SecondParameter<typeof getSiteSuggestProductsMutator>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSiteSuggestProductsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Cria um lead no CMS
 * @summary Criar um lead
 */
export const postLead = (
  postLeadBody: PostLeadBodyType<PostLeadBody>,
  options?: SecondParameter<typeof postLeadMutator>,
  signal?: AbortSignal,
) => {
  return postLeadMutator<unknown>(
    {
      url: '/lead',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postLeadBody,
      signal,
    },
    options,
  );
};

export const getPostLeadMutationOptions = <
  TError = PostLeadErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLead>>,
    TError,
    { data: PostLeadBodyType<PostLeadBody> },
    TContext
  >;
  request?: SecondParameter<typeof postLeadMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLead>>,
  TError,
  { data: PostLeadBodyType<PostLeadBody> },
  TContext
> => {
  const mutationKey = ['postLead'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLead>>,
    { data: PostLeadBodyType<PostLeadBody> }
  > = (props) => {
    const { data } = props ?? {};

    return postLead(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLeadMutationResult = NonNullable<Awaited<ReturnType<typeof postLead>>>;
export type PostLeadMutationBody = PostLeadBodyType<PostLeadBody>;
export type PostLeadMutationError = PostLeadErrorType<unknown>;

/**
 * @summary Criar um lead
 */
export const usePostLead = <
  TError = PostLeadErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLead>>,
    TError,
    { data: PostLeadBodyType<PostLeadBody> },
    TContext
  >;
  request?: SecondParameter<typeof postLeadMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postLead>>,
  TError,
  { data: PostLeadBodyType<PostLeadBody> },
  TContext
> => {
  const mutationOptions = getPostLeadMutationOptions(options);

  return useMutation(mutationOptions);
};
