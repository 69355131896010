import { useRouter } from 'next/router';
import { type ChangeEvent, type FormEvent, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useGetSiteSuggestProducts } from '#/http/api/site/site';

export function useSearchInput() {
  const router = useRouter();

  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value.trim());
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await router.push(`/catalogo?product_title=${encodeURIComponent(inputValue)}`);
  };

  const [debounced] = useDebounceValue(inputValue, 500);

  const { data: products = [] } = useGetSiteSuggestProducts(
    {
      q: debounced,
    },
    {
      query: {
        enabled: !!debounced,
      },
    },
  );

  return {
    handleChange,
    handleSubmit,
    products,
    inputValue,
  };
}
