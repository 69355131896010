import * as Menubar from '@radix-ui/react-menubar';
import Link from 'next/link';
import type { HeaderDataItemProps } from '#/common/types/catalog-filter';
import { useGetSiteHeaderNavigation } from '#/http/api/site/site';
import { Icon } from '../Icon';

const CustomClass = {
  Chevron:
    'ml-auto pl-5 text-gray-300 group-data-[highlighted]:text-gray-600 dark:text-gray-700 dark:group-data-[highlighted]:text-gray-100 transition-colors',
  Content:
    'min-w-[220px] dark:bg-background rounded-md p-1.5 pb-2.5 shadow-md shadow-black/20 bg-background dark:shadow-black dark:shadow-md [animation-duration:_400ms] [animation-timing-function:_cubic-bezier(0.16,_1,_0.3,_1)] will-change-[transform,opacity]',
  Item: 'cursor-pointer group text-[13px] leading-none rounded flex items-center h-[25px] px-[10px] relative select-none outline-none data-[state=open] data-[highlighted]:bg-black/5 dark:data-[highlighted]:bg-white/5 data-[disabled]:pointer-events-none transition-all',
};

interface MenuItemProps {
  menuContent: HeaderDataItemProps;
}

function MenuItem({ menuContent }: MenuItemProps) {
  return menuContent?.nestedItem ? (
    <Menubar.Sub>
      <a
        href={menuContent.url}
        className="cursor-pointer"
      >
        <Menubar.SubTrigger className={CustomClass.Item}>
          {menuContent.title}

          <div className={CustomClass.Chevron}>
            <Icon name="chevron-right" />
          </div>
        </Menubar.SubTrigger>
      </a>
      <Menubar.Portal>
        <Menubar.SubContent
          className={CustomClass.Content}
          alignOffset={-5}
        >
          {menuContent.nestedItem.map((subMenu, index) => (
            <MenuItem
              key={`${subMenu.title}:${index}`}
              menuContent={subMenu}
            />
          ))}
        </Menubar.SubContent>
      </Menubar.Portal>
    </Menubar.Sub>
  ) : (
    <Link href={menuContent.url}>
      <Menubar.Item className={CustomClass.Item}>{menuContent.title}</Menubar.Item>
    </Link>
  );
}

export function NavigationMenuDesktop() {
  const { data } = useGetSiteHeaderNavigation();

  return (
    <Menubar.Root className="hidden justify-between gap-1 pt-8 pb-1 text-sm uppercase lg:flex">
      <Menubar.Menu>
        <Menubar.Trigger className="outline-none hover:outline-none">
          <a
            className="flex select-none items-center rounded py-2 pr-3 pl-1 font-medium text-[13px] uppercase leading-none "
            href="/catalogo?categories=1506,1507,1509,1508,15,541"
          >
            Todas as categorias
          </a>
        </Menubar.Trigger>
      </Menubar.Menu>
      <Menubar.Menu>
        <Menubar.Trigger className="outline-none hover:outline-none">
          <a
            className="flex select-none items-center rounded px-3 py-2 font-medium text-[13px] uppercase leading-none outline-none"
            href="/catalogo"
          >
            Novidades
          </a>
        </Menubar.Trigger>
      </Menubar.Menu>
      {data?.map((menu, index) => (
        <Menubar.Menu key={`${menu.title}:${index}`}>
          <Menubar.MenubarTrigger className="flex select-none items-center justify-between gap-2 rounded px-3 py-2 font-medium text-[13px] uppercase leading-none outline-none first:pl-0">
            {menu.nestedItem ? (
              <>
                {menu.title}
                <Icon
                  name="chevron-down"
                  className="cursor-pointer"
                />
              </>
            ) : (
              <a
                href={menu.url}
                className="uppercase"
              >
                {menu.title}
              </a>
            )}
          </Menubar.MenubarTrigger>
          {menu.nestedItem && (
            <Menubar.Portal>
              <Menubar.Content
                className={CustomClass.Content}
                align="start"
                sideOffset={5}
                alignOffset={-3}
              >
                {menu.nestedItem.map((menuContent, index) => (
                  <MenuItem
                    key={`${menuContent.title}:${index}`}
                    menuContent={menuContent}
                  />
                ))}
              </Menubar.Content>
            </Menubar.Portal>
          )}
        </Menubar.Menu>
      ))}
      {/*<Menubar.Menu>
        <Menubar.Trigger className="outline-none hover:outline-none">
          <a
            className="flex select-none items-center rounded py-2 pr-1 pl-3 font-medium text-[13px] uppercase leading-none outline-none hover:outline-none"
            href="/catalogo/em-alta"
          >
            Mais Buscados
          </a>
        </Menubar.Trigger>
      </Menubar.Menu>*/}
    </Menubar.Root>
  );
}
