import dynamic from 'next/dynamic';
import { useAuthStore } from '#/common/stores/auth/AuthStoreProvider';
import { useCartStore } from '#/common/stores/cart/CartStoreProvider';
import { Button } from '#/ui/button';
import { Sheet, SheetClose, SheetContent, SheetTitle, SheetTrigger } from '#/ui/sheet';
import { Icon } from '../Icon';
import { IconWithLabel } from '../IconWithLabel';
const CartProducts = dynamic(() => import('./CartProducts'));
const CartPersonalData = dynamic(() => import('./CartPersonalData'));
const CartPaymentMethod = dynamic(() => import('./CartPaymentMethod'));
const CartAddressSettings = dynamic(() => import('./CartAddressSettings'));
const CartOrderReview = dynamic(() => import('./CartOrderReview'));

const steps: Record<number, JSX.Element> = {
  1: <CartProducts />,
  2: <CartPersonalData />,
  3: <CartPaymentMethod />,
  4: <CartAddressSettings />,
  5: <CartOrderReview />,
};

export function HeaderNavCart() {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { currentStep, isCartOpen, toggleIsCartOpen } = useCartStore((state) => ({
    currentStep: !isAuthenticated ? 1 : state.currentStep,
    isCartOpen: state.isCartOpen,
    toggleIsCartOpen: state.toggleIsCartOpen,
  }));

  return (
    <Sheet
      open={isCartOpen}
      onOpenChange={toggleIsCartOpen}
    >
      <SheetTrigger>
        <Button
          asChild
          variant="ghost"
          size="lg"
          className="h-fit w-fit p-0"
        >
          <IconWithLabel
            icon="shopping-cart"
            size={30}
            label="CARRINHO"
          />
        </Button>
      </SheetTrigger>
      <SheetContent
        side="right"
        withClose={false}
        className="flex max-w-[400px] flex-col gap-1 border-0 p-0"
      >
        <div className="flex w-full items-center justify-between bg-brand-main px-4 py-2.5">
          <SheetTitle className="cursor-default text-sm text-white/95 uppercase tracking-wide sm:text-base sm:tracking-normal">
            Carrinho
          </SheetTitle>
          <SheetClose className="rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none">
            <Icon
              name="x"
              className="h-4 w-4 text-white/95"
            />
            <span className="sr-only">Close</span>
          </SheetClose>
        </div>
        {steps[currentStep] || null}
      </SheetContent>
    </Sheet>
  );
}
