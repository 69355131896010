import { type PropsWithChildren, createContext, useContext, useRef } from 'react';
import { type StoreApi, useStore } from 'zustand';

import { type AuthStore, createAuthStore, initAuthStore } from './auth.store';

export const AuthStoreContext = createContext<StoreApi<AuthStore> | null>(null);

export const AuthStoreProvider = ({ children }: PropsWithChildren) => {
  const storeRef = useRef<StoreApi<AuthStore>>();

  if (!storeRef.current) {
    storeRef.current = createAuthStore(initAuthStore());
  }

  return (
    <AuthStoreContext.Provider value={storeRef.current}>
      {children}
    </AuthStoreContext.Provider>
  );
};

export const useAuthStore = <T,>(selector: (store: AuthStore) => T): T => {
  const authStoreContext = useContext(AuthStoreContext);

  if (!authStoreContext) {
    throw new Error('useAuthStore must be use within AuthStoreProvider');
  }

  return useStore(authStoreContext, selector);
};
