import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    PORT: z.coerce.number().optional().default(3000),
  },
  client: {
    NEXT_PUBLIC_UMAMI_ID: z.string().uuid(),
    NEXT_PUBLIC_ENTREMALHAS_API_URL: z.string().url(),
  },
  runtimeEnv: {
    PORT: process.env.PORT,
    NEXT_PUBLIC_UMAMI_ID: process.env.NEXT_PUBLIC_UMAMI_ID,
    NEXT_PUBLIC_ENTREMALHAS_API_URL: process.env.NEXT_PUBLIC_ENTREMALHAS_API_URL,
  },
});
